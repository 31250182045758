.points1 {
    margin: 0 auto; /* Centra horizontalmente */
  }


.endDate {
    margin-right: 5%;
}

.box3 {
    display: flex;
    justify-content: center; 
    align-items: center;   
    flex-direction: column; 
  }

.App3{
    background-color: #f6f9ff;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

@media screen and (max-width: 768px) {
  /* Ajustes específicos para pantallas más pequeñas */
  .endDate {
      margin-right: 2%;
      margin-left: 2%;
  }

  .box3 {
      flex-direction: column;
  }
}

@media screen and (max-width: 968px) {
  /* Ajustes específicos para pantallas más pequeñas */
  .endDate {
      margin-right: 2%;
      margin-left: 10%;
  }

  .box3 {
      flex-direction: column;
  }
}

