.box {
  display: flex;
  justify-content: center; /* This will center its children horizontally */
  align-items: center;     /* Optional: This will center its children vertically */
  flex-direction: column;  /* This ensures the children (components) stack vertically */
  margin-left: 15%;
  margin-right: 15%;
}

.alerta {
  margin-left: 15%;
  margin-right: 15%;
}


.title-text {
  color: #072f75;
  font-weight: bold;
  font-size: 24px; /* Big letters */
  padding-left: 2%;
  padding-top: 50px;
  padding-bottom: 15px;
}

.App1{
  background-color: #f6f9ff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.homework-box {
  border: 1px solid #e0e0e0;
  padding: 15px;
  margin: 15px;
  border-radius: 5px;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
}

.hw-title {
  font-weight: bold;
  font-size: 20px;
  color: #072f75;
}

.accordion-button:not(.collapsed) {
  background-color: inherit !important;
  color: e7f8fd;
}

button.accordion-button:focus {
  box-shadow: inherit;
}

/* Responsive styles */

/* For tablets and small desktops */
@media (max-width: 992px) {
  .box {
    margin-left: 10%;
    margin-right: 10%;
  }
  
  .title-text {
    font-size: 22px;
    padding-top: 30px;
    padding-bottom: 10px;
  }
  
  .alerta {
    padding-right: 10%;
    padding-left: 10%;
  }

  .hw-title {
    font-size: 18px;
  }

  .file-link {
    margin-left: 15px;
    margin-right: 20%;
    margin-top: 5px;
  }

  .accordion-body input[type="file"] {
    margin-bottom: 8px;
  }

 


}

/* For mobile phones */
@media (max-width: 576px) {
  .box, .alerta {
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .title-text {
    font-size: 18px;
    padding-top: 20px;
    padding-bottom: 8px;
  }

  .hw-title {
    font-size: 16px;
  }

  .file-link {
    margin-left: 10px;
    margin-right: 10%;
    margin-top: 4px;
  }

  .accordion-body input[type="file"] {
    margin-bottom: 6px;
  }
}

.accordion-body {
  flex-wrap: wrap; /* Allow items to wrap to the next line if space is constrained */
  align-items: center; /* Align items vertically in the center */
}

.accordion-body input[type="file"] {
  flex: 1; /* Allow it to take available space */
  margin-bottom: 10px; /* Additional space at the bottom */
}

.file-link {
  margin-left: 20px;
  margin-right: 30%;
  vertical-align: middle;
  margin-top: 6px;
}

.fecha-title {
  margin-right: 4%;
}