.content {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
}
.header {
  text-align: center;
  padding: 20px;
}
@media (max-width: 768px) {
  .content {
    max-width: 90%;
    margin: 20px auto;
    padding: 10px;
  }
}

.navbar.bg-dark {
  background-color: #ffffff !important;  
  
}
.navbar-link {
  color: #072f75 !important; 
  font-weight: bold !important;
}

.custom-toggler {
  background-color: #072f75 !important;
}