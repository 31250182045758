.points-obtained {
    margin-left: 12%;
}

.points-given {
    margin-left: 10%;
}

.delivered {
    margin-left: 20%;
}

.points4{
    margin-left: auto;
    margin-right: auto;
}