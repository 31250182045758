/* login.css */

.login-title-text1 {
  font-size: 2.5rem;
  margin-bottom: 15px;
  color: #072f75;
  text-align: center;
  font-weight: bold;
}

.login-container {
  height: 100vh;
  display: flex;
  flex-direction: column; 
  align-items: center;  
  justify-content: center; 
  position: relative;
  top: -10%;
}

.login-form {
  max-width: 450px;
  width: 100%;
  padding: 30px;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  background-color: #ffffff;
}

.login-form-group {
  margin-bottom: 20px;
}

.login-form-group:last-child {
  margin-bottom: 40px;
}

.login-form-group label {
  display: block;
  margin-bottom: 10px;
  color: #072f75;
}

.login-form-control {
  padding: 12px 15px;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
  width: 100%;
  font-size: 1rem;
  transition: border-color 0.3s;
}

.login-form-control:focus {
  border-color: #072f75;
  outline: none;
  box-shadow: none;
}

.login-error-message {
  color: #dc3545;
  font-size: 0.85rem;
  margin-top: 5px;
}

.btn.login-btn {
  color: #ffffff !important; /* Add !important to ensure the style is applied */
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
}

.login-btn:hover {
  background-color: #051c4d;
  transform: translateY(-2px);
}

.login-btn:active {
  transform: translateY(0);
}
