.tabla {
    
    padding-bottom: 3%;
    padding-left: 10%;
    padding-right: 10%;
  }

.center {
  text-align: center;
}

.Admin {
  background-color: #f6f9ff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

th.headers {
  background-color: #072f75;
  color: rgb(255, 255, 255);
  text-align: center;
}

.fecha {
  margin-right: 2%;
}

.inicio {
  margin-bottom: 4%;
}

.error-message {
  color: red; /* Color de texto rojo */
  font-size: 12px; /* Tamaño de fuente 16px */
  margin-top: 1%;
}

  .checkbox {
    margin-top: 3%;
  }
  
  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-forms {
    display: grid;
    place-items: center;
    margin-bottom: 0.5%;

  }

  .select-forms {
    display: grid;
    place-items: center;
    margin-left: 47.8%;
    margin-right: 47.8%;
    margin-bottom: 2%;

  }

  .custom-bg-gray {
    background-color: #e7e7e7;
  }