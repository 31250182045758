.box {
  display: flex;
  justify-content: center; 
  align-items: center;   
  flex-direction: column; 
  margin-left: 15%;
  margin-right: 15%;
}


.alerta2 {
  margin-left: 15%;
  margin-right: 15%;
}

/* For screens below 1024px (typically tablets and some large phones) */
@media (max-width: 1024px) {
  .box {
    margin-left: 10%;
    margin-right: 10%;
  }

  .alerta2 {
    padding-right: 12%;
    padding-left: 12%;
  }

  .correction-row {
    gap: 5px;
    padding: 5px;
  }
}

/* For screens below 768px (typically phones) */
@media (max-width: 768px) {
  .box {
    margin-left: 5%;
    margin-right: 5%;
    
  }

  .alerta2 {
    padding-right: 10%;
    padding-left: 10%;
  }

  .correction-row {
    flex-direction: column;
    gap: 5px;
  }

  .rubric-popup {
    max-width: 95%;
  }
}

/* For screens below 480px (typically smaller phones) */
@media (max-width: 480px) {
  .box {
    margin-left: 2%;
    margin-right: 2%;
  }

  .alerta2 {
    padding-right: 5%;
    padding-left: 5%;
  }
  
}

.App {
  background-color: #f6f9ff;
  
}

.correction-row {
  background-color: #fff5e1;
  border: 1px solid #ffdaa3;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background-color 0.2s, transform 0.2s;
}

.correction-row:hover {
  background-color: #ffe8b5;
  cursor: pointer;
  transform: scale(1.02);
}

/* .correction-row.has-justification {
  background-color: #a7ddaf; 
  border: 1px solid #7abf8e; 
}


.correction-row.has-justification:hover {
  background-color: #a7ddaf; 
  cursor: pointer;
  transform: scale(1.02);
} */


.correction-row:before {
  content: "\270E";
  font-size: 18px;
  margin-right: 5px;
}

.details-link {
  padding: 5px 10px;
  margin-left: 15px;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.details-link:hover {
  color: #0056b3;
}

.details-link.red-text {
  color: red;
}

.details-link.red-text:hover {
  color: darkred;
}

.custom-checkbox {
  position: relative;
  display: inline-block;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox-label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
}

.custom-checkbox-label:before,
.custom-checkbox-label:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid #007bff;
  border-radius: 4px;
  background-color: white;
}

.custom-checkbox-label:after {
  display: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23007BFF" d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>');
  background-repeat: no-repeat;
  background-position: center center;
  border: none;
}

.custom-checkbox input[type="checkbox"]:checked + .custom-checkbox-label:after {
  display: block;
}

.rubric-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);  /* Semi-transparent background */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rubric-popup {
  background-color: white;
  max-width: 80%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  position: relative; /* Relative positioning for the close button */
}

.rubric-content ul {
  margin-top: 20px;
  padding-left: 30px;
}

.paragraph{
  margin-left: 4%;
}

.corregir-link{
  margin-left: 1%;
}

/* .green-background {
  background-color: rgb(175, 221, 175);
} */

.close-rubric-btn {
  background-color: transparent;
  border: none;
  font-size: 24px; /* Increased the font size for better visibility */
  cursor: pointer;
  color: #aaa;
  position: absolute; /* Positioning it absolute to place it on the top right corner */
  top: 10px;
  right: 10px;
  padding: 5px; /* Added padding to make it easier to click */
  transition: color 0.2s; /* Transition for hover effect */
}

.link-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
  font-family: inherit;
  font-size: inherit;
}
.link-button:focus {
  outline: thin dotted;
}
.link-button:active,
.link-button:hover {
  outline: 0;
}