.faq-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.hw-title {
    flex: 1;
}

.faq-date {
    font-size: 80%; /* Tamaño de fuente más pequeño para la fecha */
    color: #777; /* Color de texto más suave para la fecha */
    margin-right: 3%;
}

.titlefaqs{
    margin-top: 2%;
    margin-bottom: 2%;
}

.faqs-box{
    margin-left: 15%;
    margin-right: 15%;
}

.faqs-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: 1rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
}