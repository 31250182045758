/* Reset any default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  

  .navbar {
    width: 100%;
  }
  
  /* Styles for the App */
  .App {
    background-color: #f6f9ff;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

/* New style for content-wrapper */
.content-wrapper {
    flex-grow: 1; /* This allows the content-wrapper to take up remaining space */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* This pushes the content to the bottom */
}
  
  /* Central content */
  .content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f6f9ff; /* Same as the App background */
    width: 80%;
    max-width: 1200px;
  }
  
  .title-text {
    color: #072f75;
    font-weight: bold;
    font-size: 3em;
    text-align: center;
    margin-bottom: 20px; /* Spacing between title and paragraphs */
  }
  
  /* For the paragraphs */
  .content-box p {
    text-align: center;
    margin-bottom: 10px; /* Spacing between paragraphs */
    color: #072f75;
    font-size: 1.2em; /* Increase font size for readability */
  }

  .content-box > p:first-child {
    font-size: 3em;  /* Makes the text bigger */
    font-weight: bold;  /* Makes the text bold */
    color: #072f75;  /* Sets the text color */
    margin: 0; /* Resets default margin */
    padding-bottom: 20px; /* Adds some space below the title */
  }
  
  /* Styles for the button */
  .content-box button {
    padding: 10px 20px;
    color: white;
    background-color: #072f75;
    border: none;
    border-radius: 5px;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .content-box button:hover {
    background-color: #052055; /* Darker shade of blue for hover effect */
  }
  
  



