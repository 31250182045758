.password-change-error-message {
  color: #dc3545;
  font-size: 0.85rem;
  margin-top: 5px;
}
.password-change-form-control {
  padding: 12px 15px;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
  width: 100%;
  font-size: 1rem;
  transition: border-color 0.3s;
}
.btn.password-change-btn {
  color: #ffffff !important; /* Add !important to ensure the style is applied */
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
}

.password-change-btn:hover {
  background-color: #051c4d;
  transform: translateY(-2px);
}

.password-change-btn:active {
  transform: translateY(0);
}

.password-change-form-group {
  margin-bottom: 20px;
  display: grid;
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.password-change-form-group label {
  color: #072f75;
}

.App1 {
  background-color: #f6f9ff;
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
}
.change-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -10%;
}
